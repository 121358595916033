import Vue from 'vue'

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'

// PRO LIGHT
import {
  faLocation,
  faPlus,
  faMinus,
  faExclamationTriangle,
  faCheck as falCheck,
  faSearch,
  faInfoCircle as falInfoCircle,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-light-svg-icons'

// PRO SOLID
import { faCheck, faEdit, faShare, faSort } from '@fortawesome/free-solid-svg-icons'

// PRO DUOTONE
import {
  faChair,
  faTrash,
  faCalendarAlt,
  faClock,
  faEnvelope,
  faInfoCircle as fadInfoCircle,
  faPhoneAlt,
  faMapMarkerAlt,
  faUserCheck,
  faSlidersH as fadSlidersH
} from '@fortawesome/pro-duotone-svg-icons'

// PRO REGULAR
import { faAngleLeft, faAngleRight, faAngleUp
} from '@fortawesome/pro-regular-svg-icons'

library.add(
  faCheck,
  faSort,
  faLocation,
  faEdit,
  faAngleLeft,
  faAngleUp,
  faAngleRight,
  faEnvelope,
  faPhoneAlt,
  faCalendarAlt,
  faChair,
  faTrash,
  faPlus,
  faMinus,
  faExclamationTriangle,
  falCheck,
  faSearch,
  falInfoCircle,
  faClock,
  faMapMarkerAlt,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faUserCheck,
  faShare,

  // REGULAR

  // DUOTONE
  fadInfoCircle,
  fadSlidersH
)

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// export default FontAwesomeIcon
Vue.component('font-awesome-icon', FontAwesomeIcon)
