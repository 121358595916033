<template>
  <div
    class="LocationCard"
    :class="classList"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseOut"
  >
    <figure
      v-if="showLocationImage"
      @click="showDrawer('drawerLocationDetail', location, 0)"
      class="LocationCard__image has-background-grey-light"
      :style="{
        backgroundImage: `url('${$options.filters.getLocationImage(
          location.Image,
          location.LocationId,
          '640'
        )}')`,
      }"
    ></figure>
    <div class="LocationCard__detail_price">
      <div class="LocationCard__details has-padding">
        <div
          :class="[`has-text-weight-bold`, !compact ? 'is-size-4' : '']"
          v-html="name"
        ></div>
        <p class="has-margin-bottom">
          <a
            v-if="interactive"
            class="link-more-info has-icon"
            @click="handleLocationOnMap"
          >
            <span :class="`${$classPrefix}icon`">
              <font-awesome-icon :icon="['fad', 'map-marker-alt']" />
            </span>
            <span v-html="address"></span>
          </a>
          <span
            v-else
            class="has-icon"
          ><span
              v-if="!compact"
              :class="`${$classPrefix}icon`"
            >
              <font-awesome-icon :icon="['fad', 'map-marker-alt']" />
            </span>
            <span v-html="address"></span></span>
        </p>
        <UpsellTemplate
          v-if="upsellItems.length"
          :upsellItems="upsellItems"
          class="has-margin-bottom"
        />
        <div
          v-if="showRating && score > 0"
          @click="showDrawer('drawerLocationDetail', location, 0)"
          class="LocationCard__review"
        >
          <span
            :class="
              `${$classPrefix}tag is-medium LocationCard__tag-score`
            "
            v-html="score > 0 ? score : 0"
          ></span>
          <span class="is-size-7 has-text-grey"><template v-if="score > 0">{{ $t('Components.LocationCard.Text_AverageFrom') }} <br /></template>
            {{
              $tc(
                'Components.LocationCard.Text_Reviews',
                location.ReviewCount,
                { score: location.ReviewCount }
              )
            }}</span>
        </div>
      </div>
      <div
        v-if="!selectedLocationId || this.LastStep === 1"
        class="LocationCard__reviewPrice has-background-info-light has-padding"
      >
        <div class="has-text-centered">
          <div
            v-if="showPricing"
            class="LocationCard__price"
          >
            <span
              :alt="pricingMethod"
              :title="pricingMethod"
            >{{ $t('Components.LocationCard.Text_From') }}
              <strong
                class="is-size-5"
                v-html="
                  $options.filters.toCurrency(lowestPrice, 'nl-NL', 'EUR')
                "
              ></strong>
              {{ pricingMethodShortner }}</span>
            <div
              v-if="!hideTotalPrice"
              class="LocationCard__priceExplanation is-size-7"
              v-text="
                $t('Components.LocationCard.Text_PriceTotal', {
                  price: $options.filters.toCurrency(
                  lowestPriceTotal,
                  'nl-NL',
                  'EUR'
                )})"
            ></div>
            <div
              v-html="$t('Components.LocationCard.Text_ExcludingOptions')"
              class="is-size-7"
            ></div>
          </div>

          <button
            @click.once="handleSelectLocationClick"
            :class="[`${$classPrefix}button`, { 'is-small': compact }, {'is-loading': showButtonLoader }]"
            class="LocationCard__button is-fullwidth is-success"
          >
          {{ buttonText }}
        </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import UpsellTemplate from '@/components/UI/UpsellTemplate'

import { decode } from '@/helpers/entity-decoder'

export default {
  name: 'LocationCard',
  props: {
    /**
     * The location object from the Available Location api
     */
    location: {
      type: Object,
      required: true,
    },
    /**
     * In a compact card, the image and rating are hidden
     * This is used in the compact MapBox popup
     */
    compact: {
      type: Boolean,
      default: false,
    },

    showLocationImage: {
      type: Boolean,
      default: true,
    },
    /**
     * In a horizontal card, the image is smaller, set to the left, with to the right the title and address
     * The rating is hidden
     */
    horizontal: {
      type: Boolean,
      default: false,
    },
    /**
     * Indicates whether hovering the card should have any effect
     */
    hoverable: {
      type: Boolean,
      default: true,
    },
    /**
     * Can be used to disable user interaction with this component
     */
    interactive: {
      type: Boolean,
      default: true,
    },
    /**
     * Can be used to hide the pricing information
     */
    showPricing: {
      type: Boolean,
      default: true,
    },
    /**
     * Can be used to hide the rating information
     */
    showRating: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    UpsellTemplate,
  },

  data() {
    return {
      hovering: false,
    }
  },

  computed: {
    ...mapState('searchStore', ['currentSearch']),
    ...mapGetters('searchStore', ['MeetingtypeId', 'ChannelId', 'LastStep']),
    ...mapGetters('availabilityStore', ['selectedLocationId']),

    name() {
      return this.location.Name
    },

    /**
     * The short 'description' of the location
     */
    address() {
      return `${this.location.Address}, ${this.location.City} `
    },
    /**
     * The rating
     */
    score() {
      return (this.location.ReviewScore / 10).toFixed(1)
    },
    hasScore() {
      return this.showRating && this.location.ReviewScore > 0
    },

    /**
     * The pricing method is always the same for all spaces in a location
     */
    calculationType() {
      let _calculationType = null

      for (let i in this.location.Spaces) {
        if (this.location.Spaces[i].SpacePrice !== null) {
          _calculationType = this.location.Spaces[i].SpacePrice.CalculationType
          break
        }
      }

      return _calculationType
    },

    /**
     * The pricing method is always the same for all spaces in a location
     */
    pricingMethod() {
      if (!this.calculationType) {
        return null
      }

      switch (this.calculationType) {
        case 'PerHourPerSeat':
          return this.$t('App.Cart.Text_PerHourPerSeat')
        case 'PerDayPartOnly':
          return this.$t('App.Cart.Text_PerDayPartOnly')
        case 'PerDayPartPerPerson':
          return this.$t('App.Cart.Text_DayPartPerPerson')
      }

      return null
    },

    pricingMethodShortner() {
      if (!this.calculationType) {
        return null
      }

      switch (this.calculationType) {
        case 'PerHourPerSeat':
          return this.$t('App.Cart.Text_PerHourPerSeatShortner')
        case 'PerDayPartOnly':
          return this.$t('App.Cart.Text_PerDayPartOnlyShortner')
        case 'PerDayPartPerPerson':
          return this.$t('App.Cart.Text_DayPartPerPersonShortner')
      }

      return null
    },

    hasWarnings() {
      return !!this.location.Warnings.length
    },

    /**
     * The lowest price is the price of the space with the lowest price
     */
    lowestPrice() {
      return Number(this.location._LowestPrice)
    },
    /**
     * The lowest price is the price of the space with the lowest price
     */
    lowestPriceTotal() {
      return Number(this.location._LowestTotalPrice)
    },
    hideTotalPrice() {
      return this.currentSearch.MeetingtypeId === 2
    },
    showSocialCaptial() {
      return (
        this.currentSearch.MeetingtypeId === 2 &&
        this.ChannelId === 1 &&
        !this.lowestPriceTotal
      )
    },

    /**
     * Decode the currency html entity
     */
    currency() {
      return decode(this.location.Spaces[0].CurrencySymbol)
    },
    /**
     * If selected, the selection button is hidden (applicable in the MapBox card popup)
     */
    isSelected() {
      return this.location.LocationId === this.selectedLocationId
    },
    /**
     * The dynamic classes applied to main element
     */
    classList() {
      return {
        'LocationCard--hovering': this.hovering,
        'LocationCard--selected': this.isSelected,
        'LocationCard--compact': this.compact,
        'LocationCard--horizontal': this.horizontal,
        'LocationCard--hoverable': this.hoverable && this.interactive,
        'LocationCard--interactive': this.interactive,
      }
    },

    upsellItems() {
      let output = this.location.Features.map((el) => el.Body)
      return output
    },

    buttonText() {
      return {
        1: this.$t('Components.LocationCard.Link_ChooseARoom'),
        2: this.$t('Components.LocationCard.Link_ChooseAWorkspace'),
        3: this.$t('Components.LocationCard.Link_ChooseADeskspace'),
      }[this.currentSearch.MeetingtypeId]
    },

    showButtonLoader() {
      return this.isBusy && this.isSelected
    }
  },

  created() {
    this.$EventBus.$on('location.map.hover', this.setHoverState)
  },

  beforeDestroy() {
    this.$EventBus.$off('location.map.hover', this.setHoverState)
  },

  methods: {
    ...mapActions('searchStore', ['goToStep']),
    ...mapActions('availabilityStore', ['setSelectedLocation']),
    ...mapMutations('searchStore', ['enableNextStep']),

    /**
     * Selects the location
     */
    handleSelectLocationClick() {
      if (!this.interactive) {
        return
      }

      this.setSelectedLocation(this.location)
      this.enableNextStep()
      this.goToStep({
        step: this.LastStep + 1,
      })
    },
    /**
     * This set of methods / events interacts with MapBox visualisations
     */
    handleMouseOver() {
      if (!this.hoverable) {
        return
      }
      this.$EventBus.$emit('location.card.hover', {
        LocationId: this.location ? this.location.LocationId : null,
      })
    },

    handleLocationOnMap() {
      if (!this.interactive) {
        return
      }
      this.$EventBus.$emit('location.card.show', {
        LocationId: this.location ? this.location.LocationId : null,
      })
      this.setSelectedLocation(this.location)

    },

    handleMouseOut() {
      this.$EventBus.$emit('location.card.hover', { LocationId: null })
    },
    /**
     * Triggered by the hover event emitted by the LocationMap
     */
    setHoverState({ LocationId }) {
      if (!this.hoverable) {
        this.hovering = false
        return
      }
      this.hovering = this.location.LocationId === LocationId
    },

    showDrawer(drawerType, location, preDefinedViewType) {
      this.$emit('showDrawerType', {
        drawerType: drawerType,
        location: location,
        preDefinedViewType: preDefinedViewType,
      })
    },
  },
}
</script>
