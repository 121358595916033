<template>
  <div :class="`${$classPrefix}upsells`">
    <div
      :class="`${$classPrefix}upsells__upsell`"
      v-for="(text, index) in upsellItems"
      :key="`u${index}`"
    >
      <span
        v-if="!hideIcon"
        :class="`${$classPrefix}icon`"
      >
        <font-awesome-icon
          :icon="['fa', 'check']"
          class="has-text-success"
        />
      </span>
      <div v-html="text"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'upsell-template',

  props: {
    hideIcon: {
      type: Boolean,
      default: false
    },
    upsellItems: {
      type: Array,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.#{$prefix}icon {
  flex-grow: 0;
  flex-shrink: 0;
}
</style>